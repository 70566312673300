import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1040da48&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=1040da48&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1040da48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Autocounter: require('C:/inetpub/wwwroot/bike-flights/components/Autocounter.vue').default})
